import $ from 'jquery';
import ScrollMagic from 'scrollmagic';

// init controller
const controller = new ScrollMagic.Controller();


/* *** *** *** ***
** 1幕目
*** *** *** *** */

/*
    0~1200
    シーンをピン留め
    */
new ScrollMagic.Scene({
    duration: 1000,
    offset: 0
})
.setPin('#firstview')
.addTo(controller);


new ScrollMagic.Scene({
    duration: 300,
    offset: 400
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#layer2").css({
        opacity: p,
    });
})
.addTo(controller);


new ScrollMagic.Scene({
    duration: 650,
    offset: 400
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#layer3").css({
        top: 100 - (p * 148) + "%",
        opacity: 1,
    });
})
.addTo(controller);
